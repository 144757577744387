import React, { useState, useEffect } from 'react';
import { Link, json, useNavigate } from "react-router-dom";
import axios from 'axios';
import './Login.css'; 
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [email, setEmail] = useState('');
  const [error_password_message,setError_password_message] = useState('');
  const [error_email_message,setError_mail_message] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const node_api = "https://murine-backend.onrender.com";
  const node_api1 = "http://localhost:3001";

  const handleLogin = async (e) => {
    e.preventDefault();
    setError_password_message("");
    setError_mail_message("");
    try {
      const response = await axios.post(`${node_api}/auth/login`, {
        email,
        password
      });

      const data = response.data;

      //console.log('Login successful:', data);

      // Store the token in session storage
      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('login_info',JSON.stringify(data))

      // Check the role and navigate accordingly
      if (data.user.role === 'admin') {
        navigate("/admin");
      } else {
        navigate("/");
      }
    } catch (error) {
      if (error.response) {
       console.log('Login failed:', error.response.data.message);
        // toast.success(error.response.data.message)
        // alert(error.response.data.message)
      if((error.response.data.message).includes("password")){
        setError_password_message(error.response.data.message)
      }else if((error.response.data.message).includes("mail")){
        setError_mail_message(error.response.data.message)
      }
        // Handle login failure (e.g., show error message)
      } else {
        console.log('An error occurred:', error.message);
        alert(error.message)
        // Handle network or other errors
      }
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    console.log('Sign Up clicked');
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2 className="login-title">Login</h2>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          /><span className='errorMessage'>{error_email_message}</span>
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          /><span className='errorMessage'>{error_password_message}</span>
        </div>
        <div className="button-group">
          <button type="submit" className="login-button">Login</button>
          <h2>New User?</h2>
          <Link to="/register">
            <button type="button" className="signup-button">Sign Up</button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
