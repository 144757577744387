import React, { useState, useRef, useEffect } from 'react';
import "./FAQ.css"
const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    contentRefs.current.forEach((ref, index) => {
      if (ref) {
        ref.style.maxHeight = activeIndex === index ? `${ref.scrollHeight}px` : '0px';
      }
    });
  }, [activeIndex]);

  return (
    <div id="accordion-collapse" data-accordion="collapse" className='faq_box'>
      <h2 id="accordion-collapse-heading-5">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded={activeIndex === 5}
          aria-controls="accordion-collapse-body-1"
          onClick={() => toggleAccordion(5)}
        >
          <span>What is $MURINE?
          </span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${activeIndex === 5 ? 'rotate-180' : ''} transition-transform duration-300 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        ref={(el) => (contentRefs.current[5] = el)}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
        aria-labelledby="accordion-collapse-heading-1"
      >
        <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Murine is a new and unique meme token.  It's a new meme world.  Murine has been roped into make a new acquisition project at Meme World.
          </p>
        </div>
      </div>

      <h2 id="accordion-collapse-heading-6">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded={activeIndex === 6}
          aria-controls="accordion-collapse-body-1"
          onClick={() => toggleAccordion(6)}
        >
          <span>What is Airdrop? And benefits?
          </span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${activeIndex === 6 ? 'rotate-180' : ''} transition-transform duration-300 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        ref={(el) => (contentRefs.current[6] = el)}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
        aria-labelledby="accordion-collapse-heading-1"
      >
        <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Airdrop is a free token gift for development. Free Airdrop Tokens available in $MURINE Skyrocket coming soon.  After that it will be of great value.
          </p>
        </div>
      </div>


      <h2 id="accordion-collapse-heading-1">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded={activeIndex === 1}
          aria-controls="accordion-collapse-body-1"
          onClick={() => toggleAccordion(1)}
        >
          <span>What are the benefits for presale Buyers?
          </span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${activeIndex === 1 ? 'rotate-180' : ''} transition-transform duration-300 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        ref={(el) => (contentRefs.current[1] = el)}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
        aria-labelledby="accordion-collapse-heading-1"
      >
        <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Buyers in the pre-sale will get huge profits on Murine's Skyrocket according to the number of tokens.
After the Murine CEX list, when the market cap becomes 1 billion as a sufficient target, the presale buyers will get 10X to 100X profit.
          </p>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-2">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded={activeIndex === 2}
          aria-controls="accordion-collapse-body-2"
          onClick={() => toggleAccordion(2)}
        >
          <span>What are the project’s future plans?
          </span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${activeIndex === 2 ? 'rotate-180' : ''} transition-transform duration-300 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        ref={(el) => (contentRefs.current[2] = el)}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
        aria-labelledby="accordion-collapse-heading-2"
      >
        <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
          <p>Following the presale, $MURINE Meme will launch its main platform and introduce features for meme creation and monetization. We aim to expand through partnerships, community events, and further innovations in blockchain technology.</p>
        </div>
      </div>

      <h2 id="accordion-collapse-heading-3">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded={activeIndex === 3}
          aria-controls="accordion-collapse-body-3"
          onClick={() => toggleAccordion(3)}
        >
          <span>Where can I trade MURINE Tokens?
          </span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${activeIndex === 3 ? 'rotate-180' : ''} transition-transform duration-300 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        ref={(el) => (contentRefs.current[3] = el)}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <p>After the exciting conclusion of our presale and Vesting Period we're planning to list MURINE Token on PancakeSwap. Stay tuned for further updates and information for that launch.</p>
        </div>
      </div>
      <h2 id="accordion-collapse-heading-4">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded={activeIndex === 4}
          aria-controls="accordion-collapse-body-4"
          onClick={() => toggleAccordion(4)}
        >
          <span>
          When MURINE goes Skyrocket?

          </span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${activeIndex === 4 ? 'rotate-180' : ''} transition-transform duration-300 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        ref={(el) => (contentRefs.current[4] = el)}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          <p>Listed on CEX after completion of vesting.  Then with the support of the Murine community, it will take its own place in the market, then like our Murine Goes to the moon Skyrocket.</p>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
