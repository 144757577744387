import React, { useState, useRef, useEffect } from 'react';
import "./Refer.css"
const Refer = ({ownReferralID}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);
  const [copied, setCopied] = useState(false);


  const handleReferCopy = () => {
    const textToCopy = document.getElementById('referal-id').value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
    console.log(textToCopy)
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    contentRefs.current.forEach((ref, index) => {
      if (ref) {
        ref.style.maxHeight = activeIndex === index ? `${ref.scrollHeight}px` : '0px';
      }
    });
  }, [activeIndex]);

  return (
    <div id="accordion-collapse" data-accordion="collapse" className='refer_box'>
      <h2 id="accordion-collapse-heading-4">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right gap-3"
          data-accordion-target="#accordion-collapse-body-3"
          aria-expanded={activeIndex === 4}
          aria-controls="accordion-collapse-body-4"
          onClick={() => toggleAccordion(4)}
        >
          <span>
          Invite a friend?
          </span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${activeIndex === 4 ? 'rotate-180' : ''} transition-transform duration-300 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        ref={(el) => (contentRefs.current[4] = el)}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
        <div className="w-full max-w-[16rem]">
      <div className="relative">
        <label htmlFor="referal-id" className="sr-only">
          Label
        </label>
        <input
          id="referal-id"
          type="text"
          className="col-span-6 bg-gray-50 border border-gray-300 text-grey-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 wallet-id"
          value={ownReferralID}
          disabled
          readOnly
        />
        <button
          onClick={handleReferCopy}
          className="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-900 dark:text-gray-400 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border"
        >
          {!copied ? (
            <span id="default-message" className="inline-flex items-center">
              <svg
                className="w-3 h-3 me-1.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
              </svg>
              <span className="text-xs font-semibold"></span>
            </span>
          ) : (
            <span id="success-message" className="inline-flex items-center">
              <svg
                className="w-3 h-3 text-blue-700 dark:text-blue-500 me-1.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span className="text-xs font-semibold text-blue-700 dark:text-blue-500 wallet-id">
                Copied
              </span>
            </span>
          )}
        </button>
      </div>
    </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
