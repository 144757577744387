import React from "react";
import "./TotalProgress.css";

const TotalProgress = () => {
  return (
    <div className="cont">
        <h1>STAGES</h1>
        
      <div class="progress-bg">
        <div class="progress-bar">
          <h3 class="raised">ROUND 1</h3>
        </div>
        <h3 class="goal">TOTAL</h3>
      </div>
    </div>
  );
};

export default TotalProgress;
