import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer-body" id="community">
        <div className="footer">
          <h1 className="black">JOIN OUR COMMUNITY</h1>
          <p>Come Join Us Now!</p>
        </div>
        <div class="flex justify-center space-x-4 mt-3">
        <a href="https://x.com/MurineCoin?t=gwOR8Nj3SBXGx0nenG7qYQ&s=09" target="_blank" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
  <i class="fab fa-twitter"></i> Twitter
</a>
<a href="https://www.instagram.com/murinecoin?utm_source=qr&igsh=MXhjZW1kNGRiaXE0OQ==" target="_blank" class="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded-full">
  <i class="fab fa-instagram"></i> Instagram
</a>

        </div>
        <p className="m-6 text-center">
          Copyright &copy; 2024 by MURINE
        </p>
      </div>
    </>
  );
};

export default Footer;
