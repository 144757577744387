import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "./Carousel.css";

const MyCarousel = () => {
  return (
    <Carousel>
      <div className="carousel">
        <img src="/Images/PHASE1.jpg" alt="Slide 1" />
      </div>
      <div className="carousel">
        <img src="/Images/PHASE2.jpg" alt="Slide 2" />
      </div>
      <div className="carousel">
        <img src="/Images/PHASE3.jpg" alt="Slide 3" />
      </div>
      <div className="carousel">
        <img src="/Images/PHASE4.jpg" alt="Slide 3" />
      </div>
    </Carousel>
  );
};

export default MyCarousel;
